import { extendTheme } from "@chakra-ui/react";
import { createBreakpoints } from "@chakra-ui/theme-tools";

const baseThemeExtension = {
  fonts: {
    body: 'Poppins, "Helvetica Neue", "Trebuchet MS", Helvetica, Arial, sans-serif',
    heading:
      'Poppins, "Helvetica Neue", "Trebuchet MS", Helvetica, Arial, sans-serif',
  },
  components: {
    Input: {
      variants: {
        outline: {
          field: {
            "&:focus": {
              boxShadow: "none",
            },
          },
        },
      },
    },
  },
  styles: {
    global: {
      "*": {
        "--chakra-shadows-outline": "none",
      },
      body: {
        backgroundColor: "#F9F9F9",
        color: "gray.700",
      },
      ".react-datepicker": {
        fontFamily:
          'Poppins, "Helvetica Neue", "Trebuchet MS", Helvetica,Arial, sans-serif !important',
      },
      ".react-datepicker__time-container--with-today-button": {
        right: "-85px !important",
        top: "-1px",
      },
      ".react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box":
        {
          width: "100% !important",
        },
      ".react-datepicker__year-option:first-of-type, .react-datepicker__year-option:last-child":
        {
          height: "20px",
        },
      ".react-datepicker__year-option:first-of-type:hover, .react-datepicker__year-option:last-child:hover":
        {
          backgroundColor: "transparent",
        },
      ".react-datepicker__year-option .react-datepicker__navigation.react-datepicker__navigation--years":
        {
          borderColor: "#ccc",
          borderStyle: "solid",
          borderWidth: "3px 3px 0 0",
          content: '""',
          height: "9px",
          top: "6px",
          width: "9px",
        },
      ".react-datepicker__navigation--years-upcoming": {
        transform: "rotate(-45deg)",
      },
      ".react-datepicker__navigation--years-previous": {
        transform: "rotate(135deg)",
      },
      ".react-datepicker__year-option .react-datepicker__navigation.react-datepicker__navigation--years:hover":
        {
          borderColor: "#a6a6a6",
        },
    },
  },
};

const themeExtensions = {
  ...baseThemeExtension,
  colors: {
    body: "#444444",
  },
  fontSizes: {
    xs: "0.6875rem",
    sm: "0.8125rem",
    md: "0.9375rem",
    lg: "1rem",
    xl: "1.1875rem",
    "2xl": "1.4375rem",
    "3xl": "2.0625rem",
  },
};

const breakpoints = createBreakpoints({
  sm: "320px",
  md: "768px",
  lg: "1280px",
  xl: "1920px",
  "2xl": "1920px",
});
const themeExtensionsV2 = {
  ...baseThemeExtension,
  breakpoints,
  colors: {
    body: "#E5E5E5",
    crmBlue: "#3182CE",
  },
  fontSizes: {
    xs: "0.723rem",
    sm: "0.823rem",
    md: "0.9375rem",
    lg: "1.138rem",
    xl: "1.383rem",
    "2xl": "1.68rem",
    "3xl": "2.041rem",
    "4xl": "2.479rem",
    "5xl": "3.012rem",
    "6xl": "3.658rem",
    "7xl": "4.444rem",
    "8xl": "5.399rem",
    "9xl": "6.558rem",
  },
  textStyles: {
    // Te style wydają się działać bardzo wybiórczo - trzeba samemu deklarować właściwości zgodnie z mockiem
    h1: {
      fontWeight: 500,
      fontSize: "3xl",
      lineHeight: "3rem",
      letterSpacing: "normal",
    },
    h2: {
      fontWeight: 500,
      fontSize: "2xl",
      lineHeight: "2.25rem",
      letterSpacing: "normal",
    },
    h3: {
      fontWeight: 500,
      fontSize: "xl",
      lineHeight: "2rem",
      letterSpacing: "normal",
    },
    h4: {
      fontWeight: 500,
      fontSize: "xl",
      lineHeight: "36px",
      letterSpacing: "normal",
    },
    h5: {
      fontSize: "lg",
      fontWeight: 500,
      lineHeight: "2rem",
      letterSpacing: "normal",
    },
    h6: {
      fontSize: "lg",
      fontWeight: 400,
      lineHeight: "32px",
      letterSpacing: "normal",
    },
    buttonLarge: {
      fontWeight: 600,
      fontSize: "lg",
      lineHeight: "1.5rem",
      letterSpacing: "normal",
    },
    subtitleLabel: {
      fontWeight: 500,
      fontSize: "md",
      lineHeight: "1.5rem",
      letterSpacing: "normal",
    },
    bodyInput: {
      fontWeight: 400,
      fontSize: "md",
      lineHeight: "1.5rem",
      letterSpacing: "wider",
    },
    buttonSmall: {
      fontWeight: 500,
      fontSize: "sm",
      lineHeight: "1.25rem",
      letterSpacing: "wide",
    },
    badgeLarge: {
      fontWeight: 600,
      fontSize: "sm",
      lineHeight: "1.25rem",
      letterSpacing: "wide",
    },
    caption: {
      fontWeight: 400,
      fontSize: "sm",
      lineHeight: "1.25rem",
      letterSpacing: "wide",
    },
    badgeSmall: {
      fontWeight: 600,
      fontSize: "xs",
      lineHeight: "1.125rem",
      letterSpacing: "wider",
    },
  },
  letterSpacings: {
    normal: "0",
    wide: "0.025em",
    wider: "0.05em",
  },
};

const customTheme: any = extendTheme(themeExtensions);
const customThemeV2: any = extendTheme(themeExtensionsV2);

export { customTheme, customThemeV2 };
export default customTheme;
