import { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";

import Loading from "../../pages/loading";

// W celu optymalizacji ładowania stron, podczas
// budowania projektu wszystkie strony powinny być ładowane
// w trybie "lazy", co umożliwia nie ładowanie dodatkowych elementów
// kiedy nie są one potrzebne.

//import Manager from "../../pages/manager";
// const Example = lazy(() => import("../../pages/example")); //PROD MODE

const Manager = lazy(() => import("../../pages/manager"));

function RoutesElement() {
  return (
    <Suspense fallback={<Loading />}>
      <Routes>
        <Route
          path="/commission/show/commission_id/:id"
          element={<Manager />}
        />
      </Routes>
    </Suspense>
  );
}

export default RoutesElement;
