import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ChakraProvider } from "@chakra-ui/react";
import { BrowserRouter } from "react-router-dom";
import customTheme from "./theme";

const root = ReactDOM.createRoot(
  document.getElementById("custom-manager") as HTMLElement
);
root.render(
  <BrowserRouter>
    <ChakraProvider resetCSS={false} theme={customTheme}>
      <App />
    </ChakraProvider>
  </BrowserRouter>
);

reportWebVitals();
